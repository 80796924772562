import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";

import s from "./AppLayout.css";
import { Header, Link } from "../../components";
import { Button, Layout, Menu, Icon, Tooltip } from "antd";
import antStyles from "antd/dist/antd.less";
import globalStyles from "../../global.css";
const { SubMenu } = Menu;
const { Sider } = Layout;
import { HOME_ROUTE } from "../../routes";
import history from "../../history";
import paginationStyles from "react-horizontal-scrolling-menu/dist/styles.css";
import { handleStripePortalRedirect } from "../../reducers/user.js";
import { clearForms } from "../../reducers/product";

import { SIDER_WIDTH } from "../../constants";
const regex_cosmode = /cosmode/;

class AppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      showSider: false,
      mounted: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
    this.setState({
      mounted: true,
    });
    //      this.hasCosmeticFeature();
  }

  manageSubscription = () => {
    this.props.handleStripePortalRedirect();
  };

  // hasCosmeticFeature = () => {
  //     if (this.props.user) {
  //     const obj = this.props.user.feature.find(o => o.name === 'cosmetic');
  //     if (obj)
  //         this.setState({
  //           cosmeticFeatureAllowed: true,
  //         });
  //     }
  // }

  updateWindowDimensions = (event) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  getLogo = (sider_width) => {
    const logoimg = (
      <img className={s.logoimg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/bplogo.svg" alt={"logo"} loading="lazy" />
    );

    if (sider_width == 0) {
      return <span onClick={this.toggle}>{logoimg}</span>;
    } else if (this.props.currentRouteName === HOME_ROUTE) {
      return logoimg;
    } else {
      return <Link to={HOME_ROUTE}>{logoimg}</Link>;
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  handleClickOutside = async (event) => {
    if (this.node.contains(event.target)) {
      return;
    }

    await this.setState({ collapsed: true });
  };

  static defaultProps = {
    footer: true,
  };

  siderLeave = () => {
    this.setState({
      collapsed: true,
    });
  };

  siderEnter = () => {
    this.setState({
      collapsed: false,
    });
  };

  render() {
    const { hideSider, footer, currencies, currentRouteName } = this.props;

    const cosmode = process.env.BROWSER && regex_cosmode.test(history.location.pathname);
    const user = this.props.user;
    const sider_width = this.state.windowWidth && this.state.windowWidth > 880 ? SIDER_WIDTH : 0;
    // mobileForNav does not exist
    const sider_class = sider_width === 0 ? s.mobileForNav : s.forNav;
    const content_class = hideSider ? s.nosiderContent : s.content;

    const handleLogout = () => {
      var widget = document.getElementById("searchie-fixed-btn");
      if (widget) widget.parentNode.removeChild(widget);
      var script = document.getElementById("widget_script-fixed-btn");
      if (script) script.parentNode.removeChild(script);
    };

    return (
      <Layout className={s.container}>
        {this.state.mounted && currencies && (
          <React.Fragment>
            <div>
              {!hideSider && this.props.user != null && (
                <React.Fragment>
                  <div className={s.leftmenu} ref={(node) => (this.node = node)}>
                    <div className={s.topleftnav}>
                      <div className={s.logo}>{this.getLogo(sider_width)}</div>
                    </div>
                    {this.props.user != null && (
                      <div className={sider_class}>
                        <Sider
                          className={s.sider}
                          collapsible
                          collapsedWidth={sider_width}
                          collapsed={this.state.collapsed}
                          onMouseLeave={this.siderLeave}
                          onMouseEnter={this.siderEnter}
                          trigger={null}
                        >
                          <Menu mode="inline" className={s.menuMobileTop}>
                            <Menu.Item className={s.ant_menu_horizontal} key="home" onClick={() => history.push("/")}>
                              <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/home.svg" />
                              <span>Home</span>
                            </Menu.Item>
                            <Menu.Item
                              className={s.ant_menu_horizontal}
                              key="create"
                              onClick={() => {
                                {
                                  /* this.props.clearForms(); */
                                }
                                history.push("/product");
                                {
                                }
                              }}
                            >
                              <img
                                className={s.navsvg}
                                src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/createaromatherapy.svg"
                              />
                              <span>Aromatherapy</span>
                            </Menu.Item>
                            {this.props.cosmeticFeatureAllowed ? (
                              <Menu.Item
                                className={s.ant_menu_horizontal}
                                key="createcosmetic"
                                onClick={() => {
                                  history.push("/cosmode");
                                  {
                                  }
                                }}
                              >
                                <img
                                  className={s.navsvg}
                                  src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/createskincare.svg"
                                />
                                <span>Skincare</span>
                              </Menu.Item>
                            ) : (
                              <Menu.Item className={s.ant_menu_horizontal} key="createcosmetic">
                                <img
                                  className={s.navsvg}
                                  src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/createskincare.svg"
                                />
                                <Tooltip
                                  placement="topRight"
                                  title={
                                    <div className={s.upgradeDiv}>
                                      <h3>Upgrade now to access the Skincare feature </h3>
                                      <Button onClick={this.manageSubscription}>Upgrade Now</Button>
                                    </div>
                                  }
                                >
                                  <span style={{ color: "#C6C6C6" }}>Skincare</span>
                                </Tooltip>
                              </Menu.Item>
                            )}
                            <Menu.Item className={s.ant_menu_horizontal} key="listproducts" onClick={() => history.push("/listproducts")}>
                              <img
                                className={s.navsvg}
                                src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/myformulations.svg"
                              />
                              <span>My Formulations</span>
                            </Menu.Item>
                            <Menu.Item className={s.ant_menu_horizontal} key="inventory" onClick={() => history.push("/inventory")}>
                              <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/inventory.svg" />

                              <span>Inventory</span>
                            </Menu.Item>
                            <SubMenu
                              className={s.ant_menu_horizontal}
                              title={
                                <span className="submenu-title-wrapper">
                                  <img
                                    className={s.navsvg}
                                    src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/community.svg"
                                  />

                                  <span>Community</span>
                                </span>
                              }
                            >
                              <Menu.Item
                                key="setting:1"
                                onClick={() =>
                                  window.open("https://www.facebook.com/groups/2081197135340829/?source_id=109407770469194", "_blank")
                                }
                              >
                                <span>FB Group AIB </span>
                              </Menu.Item>
                              <Menu.Item
                                key="setting:2"
                                onClick={() => window.open("https://www.facebook.com/groups/1016692035359049/", "_blank")}
                              >
                                <span>FB Group BP</span>
                              </Menu.Item>
                            </SubMenu>
                            <SubMenu
                              className={s.ant_menu_horizontal}
                              title={
                                <span className="submenu-title-wrapper">
                                  <img
                                    className={s.navsvg}
                                    src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/tutorials.svg"
                                  />

                                  <span>Tutorials</span>
                                </span>
                              }
                            >
                              <Menu.Item className={s.ant_menu_horizontal} key="setting:3" onClick={() => history.push("/tutorials")}>
                                <span>BP 4.0 Tutorials </span>
                              </Menu.Item>

                              <Menu.Item className={s.ant_menu_horizontal} key="setting:4" onClick={() => history.push("/tutorials45")}>
                                <span>BP 4.5 Tutorials </span>
                              </Menu.Item>

                              <Menu.Item
                                key="setting:5"
                                onClick={() => window.open("https://app.searchie.io/widget/Pm1Z07aLl2#/", "_blank")}
                              >
                                <span>All Tutorials</span>
                              </Menu.Item>

                              <Menu.Item
                                key="setting:6"
                                onClick={() => window.open("https://www.youtube.com/@BlendPrecisely/streams", "_blank")}
                              >
                                <span>YouTube</span>
                              </Menu.Item>
                            </SubMenu>

                            <Menu.Item className={s.ant_menu_horizontal} key="feedback" onClick={() => history.push("/feedback")}>
                              <img
                                className={s.navsvg}
                                src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/featurerequest.svg"
                              />

                              <span>Feature Request</span>
                            </Menu.Item>

                            <Menu.Item className={s.ant_menu_horizontal} key="resources" onClick={() => history.push("/resources")}>
                              <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/resources.svg" />

                              <span>Resources</span>
                            </Menu.Item>

                            <Menu.Item key="about" className={s.ant_menu_horizontal} onClick={() => history.push("/about")}>
                              <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/about.svg" />

                              <span>About</span>
                            </Menu.Item>
                            {this.props.cosmeticFeatureAllowed ? (
                              <Menu.Item key="ai" className={s.ant_menu_horizontal} onClick={() => history.push("/ai")}>
                                <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/ai.svg" />

                                <span>AI Studio</span>
                              </Menu.Item>
                            ) : (
                              <Menu.Item className={s.disabled} key="ai" className={s.ant_menu_horizontal}>
                                <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/ai.svg" />
                                <Tooltip
                                  placement="topRight"
                                  title={
                                    <div className={s.upgradeDiv}>
                                      <h3>Upgrade now to access the AI feature </h3>
                                      <Button onClick={this.manageSubscription}>Upgrade Now</Button>
                                    </div>
                                  }
                                >
                                  <span style={{ color: "#C6C6C6" }}>AI Studio</span>
                                </Tooltip>
                              </Menu.Item>
                            )}
                            {this.props.user && this.props.user.is_staff && (
                              <Menu.Item key="aitest" className={s.ant_menu_horizontal} onClick={() => history.push("/aitest")}>
                                <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/ai.svg" />

                                <span>AI Test</span>
                              </Menu.Item>
                            )}
                            

                            {/* <Menu.Item className={s.ant_menu_horizontal} key="ingrequest" onClick={() => history.push("/ingrequest")}> */}
                            {/*   <Icon type="form" /> */}
                            {/*   <span>Ingredient Request</span> */}
                            {/* </Menu.Item> */}
                            {/* <Menu.Item className={s.ant_menu_horizontal} key="pricing" onClick={() => history.push("/pricing")}> */}
                            {/*   <Icon type="compass" /> */}
                            {/*   <span>Pricing</span> */}
                            {/* </Menu.Item> */}
                          </Menu>
                        </Sider>
                        <Header toggleSider={this.toggle} />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
            <Layout.Content className={content_class}>{this.props.children}</Layout.Content>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

const mapState = (state) => ({
  user: state.user.user,
  currencies: state.global.currencies,
  currentRouteName: state.global.currentRouteName,
  cosmeticFeatureAllowed: state.user.cosmeticFeatureAllowed,
});

const mapDispatch = {
  clearForms,
  handleStripePortalRedirect,
};

export default connect(mapState, mapDispatch)(withStyles(antStyles, paginationStyles, globalStyles, s)(AppLayout));
