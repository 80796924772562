import React from "react";
import { connect } from "react-redux";
import s from "./Header.css";
import { Link } from "../../components";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { PRODUCT_ROUTE, LOGOUT_ROUTE, USER_ROUTE, USER_PREFERENCES } from "../../routes";
import { changeCurrency, setInitialCurrencies } from "../../reducers/global";
import { updateUserPreferences, setSessionCurrency } from "../../reducers/user";
//import { handleCurrencyChange } from "../../reducers/product";
import { Dropdown, Icon, Layout, Menu, Tooltip, Select, Popconfirm } from "antd";
import messages from "./messages";
import { injectIntl } from "react-intl";
const { SubMenu } = Menu;
const { Sider, Header2, Content } = Layout;
const TooltipDelay = 0.5;
import { version } from "../../version";
import PropTypes from "prop-types";

import history from "../../history";
import { clearForms } from "../../reducers/product";

class Header extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    sessionCurrency: PropTypes.object.isRequired,
    selectedProduct: PropTypes.object.isRequired,
    setSessionCurrency: PropTypes.object.isRequired,
    changeCurrency: PropTypes.object.isRequired,
    currencies: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    currentRouteName: PropTypes.object.isRequired,
    apiUrl: PropTypes.object.isRequired,
    toggleSider: PropTypes.object.isRequired,
    setInitialCurrencies: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      user_image: null,
    };
  }

  async componentDidMount() {
    await this.props.setInitialCurrencies();
    this.setState({ user_image: this.props.user.image });
    const preferences = this.props.user && this.props.user.preferences;
    let currency_key = preferences && preferences != "{}" && preferences.currency != null ? preferences.currency : null;

    currency_key = currency_key ? currency_key : "USD";
    this.changeCurrencyFunc(currency_key);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currency !== this.props.currency || prevProps.sessionCurrency !== this.props.sessionCurrency) {
      if (this.props.sessionCurrency) {
        this.changeCurrencyFunc(this.props.sessionCurrency);
      } else if (this.props.selectedProduct) {
        this.changeCurrencyFunc(this.props.selectedProduct.currency.name);
      } else {
        const preferences = this.props.user && this.props.user.preferences;
        let currency_key = preferences && preferences != "{}" && preferences.currency != null ? preferences.currency : null;
        currency_key = currency_key ? currency_key : "USD";

        this.changeCurrencyFunc(currency_key);
      }
    }
    if (prevState.user_image !== this.props.user.image) {
      this.setState({ user_image: this.props.user.image });
    }
  }

  getProfileImage = () => {
    return this.state.user_image ? (
      <img src={this.props.user.image} className={s.user_image} loading="lazy" />
    ) : (
      <Icon type="user" className={s.accountIcon} className={s.user_icon_image} />
    );
    /* {user.logo ? (
     *     <img src={user.image} className={s.user_image}/>
     * ) : (
     *     <Icon type="user" className={s.accountIcon}  className={s.user_image}/>
     * )
     * } */
  };

  getShoppingListContent = () => {
    if (this.props.shopping_products.length > 0) {
      return (
        <div>
          {this.props.shopping_products.results.map((item, index) => {
            return <h1>{item.name}</h1>;
          })}
        </div>
      );
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  changeCurrencyFunc = (key) => {
    this.props.setSessionCurrency(key);
    this.props.changeCurrency(key);
  };

  render() {
    const { currency, user, currencies, intl, currentRouteName, apiUrl } = this.props;

    const customeradmin_url = apiUrl + "/pands/customwarnings/";

    // public tutorials
    let tutorials = "https://youtu.be/B9rcHEN8Ezs";
    let user_name = "";
    if (user) {
      // internal tutorials
      tutorials = "https://academy.blendprecisely.io/products/blend-precisely";
      if (user.first_name) {
        if (user.last_name) {
          user_name = `${user.first_name} ${user.last_name}`;
        } else {
          user_name = `${user.first_name}`;
        }
      } else {
        user_name = user.email;
      }
    }

    //      const profile_image = getProfileImage()

    const handleLogout = () => {
      var widget = document.getElementById("searchie-fixed-btn");
      if (widget) widget.parentNode.removeChild(widget);
      var script = document.getElementById("widget_script-fixed-btn");
      if (script) script.parentNode.removeChild(script);
    };

    const handlePreferencesLink = () => {
      this.props.clearForms(this.props.productnavref);
      history.push("/preferences");
    };

    const handleAccountLink = () => {
      this.props.clearForms(this.props.productnavref);
      history.push("/account");
    };

    let is_product_path = null;
    if (history && history.location && history.location.pathname && /\/product|cosmode/.test(history.location.pathname)) {
      is_product_path = true;
    }

    return (
      <Layout.Header className={s.header}>
        {currencies && currency && (
          <div className={s.rightMenu}>
            {is_product_path && (
              <Select
                showSearch
                className={s.currency}
                value={this.props.currency.key}
                onSelect={(e) => this.changeCurrencyFunc(e)}
                size="small"
              >
                {currencies.map((item) => (
                  <Select.Option key={item.key} value={item.key} title={item.label}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            )}

            {user && (
              <React.Fragment>
                {this.getProfileImage()}
                <Dropdown
                  arrow={true}
                  placement="bottomLeft"
                  overlayClassName={s.dropdown}
                  overlay={
                    <Menu selectedKeys={[]}>
                      <Menu.Item key={USER_ROUTE}>
                        {history.location.pathname == `/${PRODUCT_ROUTE}` ? (
                          <Popconfirm
                            title={intl.formatMessage(messages.unsaved)}
                            okText="OK"
                            onConfirm={handleAccountLink}
                            cancelText="Cancel"
                          >
                            {intl.formatMessage(messages.account)}
                          </Popconfirm>
                        ) : (
                          <span onClick={handleAccountLink}>{intl.formatMessage(messages.account)}</span>
                        )}
                      </Menu.Item>
                      {/* <Menu.Divider /> */}
                      <Menu.Item key={USER_PREFERENCES}>
                        {history.location.pathname == `/${PRODUCT_ROUTE}` ? (
                          <Popconfirm
                            title={intl.formatMessage(messages.unsaved)}
                            okText="OK"
                            onConfirm={handlePreferencesLink}
                            cancelText="Cancel"
                          >
                            {intl.formatMessage(messages.preferences)}
                          </Popconfirm>
                        ) : (
                          <span onClick={handlePreferencesLink}>{intl.formatMessage(messages.preferences)}</span>
                        )}
                      </Menu.Item>
                      {/* <Menu.Divider /> */}
                      <Menu.Item key="version">
                        <span>Version: {version}</span>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <span className={s.userDropdown}>
                    <span className={s.username}>{user_name}</span>
                  </span>
                </Dropdown>
                <Tooltip placement="bottomLeft" mouseEnterDelay={TooltipDelay} title="Sign out">
                  <Link onClick={handleLogout} className={s.logout} to={LOGOUT_ROUTE}>
                    <Icon type="poweroff" />
                  </Link>
                </Tooltip>
              </React.Fragment>
            )}
          </div>
        )}
      </Layout.Header>
    );
  }
}

const mapState = (state) => ({
  currency: state.global.currency,
  currencies: state.global.currencies,
  sessionCurrency: state.user.sessionCurrency,
  currentRouteName: state.global.currentRouteName,
  user: state.user.user,
  apiUrl: state.global.apiUrl,
  selectedProduct: state.product.selectedProduct,
  selectedRawMaterials: state.rawMaterials.selectedRawMaterials,
  sessionCurrency: state.user.sessionCurrency,
  productnavref: state.product.productnavref,
});

const mapDispatch = {
  changeCurrency,
  updateUserPreferences,
  setSessionCurrency,
  clearForms,
  setInitialCurrencies,
};

export default injectIntl(connect(mapState, mapDispatch)(withStyles(s)(Header)));
